
/* DWA BOXY: PODSTAWOWY FORMY ORAZ PONIŻEJ Z GRADIENTEM */

/* background: #ffffff url('../../../../public/assets/box-gradient.png') top left repeat-x;  */

/* ===== ZMIENNE ===== */

body[data-theme="dark"] {
  --box_header_background: #354657; /* #43454b; */
  --box_header_font_color: var(--global_font_color_basic); /*d3d3d3*/
  --box_content_background: #18191b;
  --box_form_content_background: #1d1e21;
  --box_border_color: #313948;
  --form_border_color: #3f4a5d;
}

/* ===== ~ZMIENNE ===== */



/* === BOX FORMY PODSTAWOWEJ === */

/* obszar pod zewnętrznym borderem boxa formy */
body[data-theme="dark"] .ui.clearing.segment { 
  border-color: var(--box_border_color);
  background-color: var(--box_content_background);
  border-width: 2px;
}

/* nagłówek boxa formy np. "Formularz logowania" */
body[data-theme="dark"] .ui.attached.message { 
  background-color: var(--box_header_background) !important;
  color: var(--box_header_font_color) !important; 
  border-width: 1px 1px 0 1px !important;
}

/* pasek message pod formą, np. "wyślij ponownie wiadomość aktywacyjną */
body[data-theme="dark"] .bottomBarText {
  /* background-color: var(--box_header_background) !important; */
  color: var(--box_header_font_color) !important; 
  border-width: 1px 1px 0 1px !important;
}

body[data-theme="light"] .bottomBarText {
  /* background-color: var(--box_header_background) !important; */
  color: black !important; 
  border-width: 1px 1px 0 1px !important;
}

/* obszar dookoła pól formularza, również 2-gi wewnętrzny border */
body[data-theme="dark"] .ui.form { 
  background-color: var(--box_form_content_background); /*#1d1e21;  var(--box_form_content_background); */
  border: 1px solid var(--form_border_color) !important;
  border-top: 0px !important;
}

body[data-theme="dark"] .ui.message { /* tło dookoła box-a przycisków "rejestruj, przypomnij hasło" */
  background-color: var(--box_content_background) !important;
  border-color: var(--box_border_color) !important;
  border: 2px solid var(--box_border_color) !important;
}

body[data-theme="dark"] .ui.left.icon.input {
  width: 100% !important;
}

/* === ~BOX FORMY PODSTAWOWEJ === */

/* === BOX FORMY Z GRADIENTEM === */
/* #f0f1f3 na dole: #fcfcfc */
#box /*LIGHT, przez klasę nie chce działać ..., może dlatego że są nadpisywane style */
{
  border: solid 1px #d8dadc;
  border-radius: 8px;
  /* background: #ffffff url('../../../../public/assets/box-gradient.png') top left repeat-x;  zamienione na gradient */
  background: linear-gradient(#f0f1f3, #fcfcfc) !important;
  box-shadow: inset 0px 0px 0px 1px #ffffff;
  text-shadow: 0px 1px 0px #ffffff;
}

body[data-theme="dark"] #box /* przez klasę nie chce działać ..., może dlatego że są nadpisywane style */
{
  color: var(--global_font_color_basic);

  border: 2px solid var(--form_border_color) !important;
  border-radius: 8px;
  background: linear-gradient(135deg, #232327, #020d0f, #2b2d31) !important; /* musi być z important */

  box-shadow: inset 0px 0px 0px 1px #6a7d9b; /* rozjaśniony border */
  text-shadow: 0px 1px 0px #6a7d9b; /* jest potrzebny jako reset po białym */
}

/* === BOX FORMY Z GRADIENTEM === */