/* === KOMUNIKATY PROMPTS === */

/* ZMIENNE */

  body[data-theme="dark"] {
    --input_prompt_background: #a0aab9;;
  }

/* ~ZMIENNE */

 /* == ERROR == */

 body[data-theme="dark"] .ui.error.icon.message {
   background-color: var(--input_prompt_background) !important; /* #9999aa */
   border-left: 7px solid #9f5052 !important;
   color: #953032;
 }

 body[data-theme="dark"] .exclamation.circle.icon {
  color: #9f3a38;
 }

 /* == SUCCESS == */

 body[data-theme="dark"] .ui.success.icon.message {
  /* background-color: var(--icon_message_box_background) !important; #9999aa */
  background-color: var(--input_prompt_background) !important; /* #9999aa */
  color: #17622a;
  border-left: 7px solid #316e46 !important;
 }

 body[data-theme="dark"] .check.circle.icon {
  color: #17622a; /* #436842 */
 }

 /* == INFO == */

 body[data-theme="dark"] .ui.info.icon.message {
  background-color: var(--input_prompt_background) !important; /* #9999aa */
  color: #113e58;
  border-left: 7px solid #2e546b !important;
 }

 body[data-theme="dark"] .info.circle.icon {
  color: #113e58;
 } 

 /* */

 .ui.error.icon.message span, .ui.success.icon.message span, .ui.info.icon.message span {
  font-size: 22px !important;
  font-weight: bold;
 }

  /* === INPUT FIELD ERROR PROMPT === */
  body[data-theme="dark"] .ui.pointing.above.prompt.label {
    background-color: var(--input_prompt_background) !important; /* ok: #bbc7d9 #b8c3d5 */
  }
  /* === ~INPUT FIELD ERROR PROMPT === */
  
 /* === ~KOMUNIKATY PROMPTS === */
