.dark_mode {
    margin-top: -20px;
    margin-left: 10px;
}

/* cały slider kiedy zaznaczone jest słoneczko */
.dark_mode_label {
    width: 65px;
    height: 30px;
    position: relative;
    display: block;
    background-color: rgba(235,235,235,0.6); /* #ebebeb;*/ /* #2dcff3; domyślnie szary: #ebebeb; */
    /* bez opacity, gdyż później na child element nie można jej zmienić, dlatego rgba */
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;

}

/* kółko słońca - bez promieni, oryginalnie: linear-gradient(180deg, #ffcc89, #d8860b); */

.dark_mode_label:after {
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    left: 3px;
    /* background: linear-gradient(180deg, #e9e51b, #eed35a); */
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: 180px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;


}
.dark_mode_input {
    width: 0;
    height: 0;
    visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
    background: #242424;
}

/* tło ikonki księżyca */
.dark_mode_input:checked + .dark_mode_label:after {
    left: 62px;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
    width: 30px;
}

.dark_mode_label svg {
    position: absolute;
    width: 20px;
    top: 5px;
    z-index: 100;
}

/* same promienie słońca */
.dark_mode_label svg.sun {
    left: 5px;
    fill: #fff;
    transition: 0.3s;
}
.dark_mode_label svg.moon {
    left: 40px;
    fill: #7e7e7e;
    transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
    fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
    fill: #fff;
}
