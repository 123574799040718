/* === POLA INPUT TEKSTOWE FORMULARZA LOGOWANIA === */

[data-theme="dark"] { 
    /* --input_text_focused_background: #4a4e55; przeniesione do globalnej */
    --input_text_icon_color: white;
    --input_text_invalid_border_color: #a4272b;
    --input_text_invalid_focused_border_color: red;
    --input_text_invalid_placeholder_color: #9f3a38;
    --input_text_invalid_focused_placeholder_color: red;
    /* --input_text_color: white; przeniesione do globalnej */
    --input_text_invalid_background: #32353a;
    --input_text_invalid_placeholder_font_weight: bold;
   
    
    /* === INPUT FIELD ERROR PROMPT === */
     
    
    /* === ~ INPUT FIELD ERROR PROMPT === */  
  
  /* === ~POLA FORMULARZA LOGOWANIA === */
  
  }
/* === POLA TEKSTOWE FORMULARZA LOGOWANIE body[data-theme="dark"] === */

body[data-theme="dark"] input:focus, body[data-theme="dark"] textarea { /* pole focused */
  background-color: var(--global_control_focused_background) !important; /* #4a4e55 */
}

body[data-theme="dark"] input, body[data-theme="dark"] textarea { /* pole nie focused */
  background-color: var(--global_control_background) !important; /* #32353a */
  color: var(--global_control_color) !important; /* white */
}

body[data-theme="dark"] .icon {
  color: var(--input_text_icon_color); /* white;*/
}

body[data-theme="dark"] [aria-invalid] {
  border-color:var(--input_text_invalid_border_color) !important; /* #a4272b */
  background-color: var(--input_text_invalid_background) !important; 
  border-width: 2px !important;
}

body[data-theme="dark"] [aria-invalid]:focus {
  border-color:var(--input_text_invalid_focused_border_color) !important; /*red*/
}

body[data-theme="dark"] [aria-invalid]::placeholder {
  /* color:#a4272b !important; */
  color: var(--input_text_invalid_placeholder_color) !important; /* #9f3a38 */
  opacity: 1; /* Firefox */
  font-weight: var(--input_text_invalid_placeholder_font_weight); /* bold */
}

body[data-theme="dark"] [aria-invalid]:focus::placeholder {
  color: var(--input_text_invalid_focused_placeholder_color) !important; /* red */
  opacity: 1; /* Firefox */
  font-weight: normal;
}



/* === ~POLA TEKSTOWE FORMULARZA LOGOWANIE === */
