.noContentMessage {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-style: italic;
}

.containerHMargin { /* odstępy między paskiem navbar a polem formularza, po złożoniu na mobile */
  padding-top: 50px;    /* musi być padding, bo margin przeszkadza przy centrowaniu w pionie */
  padding-bottom: 50px; 
}

.fieldMargin { /* rozsuwa pola formularza, użyte w SearchForm */
  margin-top: 10px !important; 
  margin-bottom: 10px !important;
}

.formPadding { /* brzegi boxa formy nad i pod polami, użyte w SearchForm */
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ui.stackable.table { /* tabela do formularza */
  border: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
