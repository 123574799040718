/* === RADIX CHECKBOX === */

:root {
  --radix_checkbox_background: #eaeaea;
  --radix_checkbox_background_hover: #f3f3f3;
  --radix_checkbox_label: var(--global-font_color_basic);
}

body[data-theme="dark"] {
  --radix_checkbox_background: var(--global_control_background); /* var(--global_input_text_background); */
  --radix_checkbox_background_hover: var(--global_control_focused_background); /* var(--input_text_focused_background); */
  --radix_checkbox_label: var(--global-font_color_basic);
}

.CheckboxRoot { /* potrzebna klasa stylująca box na tick-a */
  all: unset;
}

.CheckboxRoot {
  background-color: var(--radix_checkbox_background); /* #32353a; */
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--radix_checkbox_background);
}
.CheckboxRoot:hover {
  background-color: var(--radix_checkbox_background_hover); /* var(--violet-3); */
  border: 1px solid #85b7d9;
}

.CheckboxRoot:focus {
  border: 1px solid #85b7d9;
}


.radixCheckboxLabel {
  color: var(--radix_checkbox_label); /* d3d3d3 */
  padding-left: 22px;
  font-size: 23px;
  line-height: 1;
}

.radixCheckboxErrorLabel {
  color: #c53f44; /* na zmienną */
  padding-left: 22px;
  font-size: 24px;
  line-height: 1;
}

/* === ~ RADIX CHECKBOX === */
