:root {
  /* dark menu */
  --dark_menu_opt_bgc_hovered: #393940; /* wykorzystywane w kodzie */
  --dark_menu_opt_bgc_unhovered: #232327;
  --dark_menu_frame_hovered: #12646d;
  --dark_menu_frame_unhovered: #0f4e56;
  /* ~dark menu */
  /* light menu */
  --light_menu_opt_bgc_hovered:#f2f2f2; /* wykorzystywane w kodzie */
  --light_menu_opt_bgc_unhovered: #ffffff;
  --light_menu_frame_hovered: #f2f2f2;
  --light_menu_frame_unhovered: #f2f2f2;
  /* ~light menu */ 
}  

/* ============= &MENU DARK ROZWIJALNE ==============*/

/* romb !important is part of the CSS rule, not var's value 
 lepiej dawać same linki albo tekst, np. jako same linki, bo z tym text jest problem z selektorem przy hoover
 cała konfiguracja jest przez zmienne root-a, strzałka górna jest obsługiwana w kodzie React

 bez wrzucenia w div-a w jsx nie sposób było nadpisać stylu default
 */

/* === STRZAŁKA ROMB === */

body[data-theme="dark"] div.menu.transition.visible:after { /* przez chwilę się pojawiało */
  background-color: var(--arrow_bg_color, var(--dark_menu_opt_bgc_unhovered)) !important;

  z-index: -20 !important; /* ukrywa migający romb, ewentualnie przez overflow */
}

/* === OPCJE === */

/* tło opcji, nie najechane */
body[data-theme="dark"] a.item[role=option] {
  background-color: var(--dark_menu_opt_bgc_unhovered) !important;
  color: #e9e9e9 !important;
}

/* tło opcji, najechane */
body[data-theme="dark"] a.item[role=option]:hover {
  background-color: var(--dark_menu_opt_bgc_hovered) !important;
}

/* === OBRAMÓWKA MENU === */

body[data-theme="dark"] div.menu { /* wąskie obramowanie całości, tło drugiej opcji po rozwinięciu oraz tej wąskiej obramówki, obramówka jest podświetlana przy najechaniu na opcję typu text jak i zwykłą link */
  background-color: var(--dark_menu_frame_unhovered) !important;
  border-top: 0px !important;
}

body[data-theme="dark"] div.menu:hover { /* wąskie obramowanie po najechaniu */
  background-color: var(--dark_menu_frame_hovered) !important;  /* jasno szare: #393940 */
  border-top: 0px !important;
}

/* ============= ~MENU DARK ============== */

/* ============= MENU LIGHT ============== */

/* ------------------------ 
   konieczne była deklaracja koloru, tutaj podświetlanie strzałki
   zostało całkowicie wyłączone
   ------------------------ */

div.dropdownMenuItems {
  color: blue !important;
}

/* === STRZAŁKA ROMB === */

div.menu.transition.visible:after { /* przez chwilę się pojawiało */
  background-color: white !important; /* tak samo jak w oryginalnym UI nie jest zmieniane */

  z-index: -20 !important; /* ukrywa migający romb, ewentualnie przez overflow */
}

/* === OPCJE === */

/* tło opcji, nie najechane */
a.item[role=option] {
  background-color: var(--light_menu_opt_bgc_unhovered) !important;
  color: black !important; /* konieczne jest nadpisanie też tekstu */
}

/* tło opcji, najechane */
a.item[role=option]:hover {
  background-color: var(--light_menu_opt_bgc_hovered) !important;
}

/* === OBRAMÓWKA MENU === */

div.menu { /* wąskie obramowanie całości, tło drugiej opcji po rozwinięciu oraz tej wąskiej obramówki, obramówka jest podświetlana przy najechaniu na opcję typu text jak i zwykłą link */
  background-color: var(--light_menu_frame_unhovered) !important;
  border-top: 0px !important;
}

div.menu:hover { /* wąskie obramowanie po najechaniu */
  background-color: var(--light_menu_frame_hovered) !important;  /* jasno szare: #393940 */
  border-top: 0px !important;
}
/* ============= ~MENU LIGHT ============= */
