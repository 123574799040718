/* po wybraniu opcji nie traci focus automatycznie */
/* musi mieć rozdzielony styl przez div.semanticSelect bo inaczej style łapią nawet elementy navbar-a */
/* UWAGA: aby styl działał musi być objęcie w div.semanticSelect */

/* LIGHT */


/* ======= DARK ======= */
/* div[role="combobox"] jest dla dropdown */

body[data-theme="dark"] div[role="listbox"], body[data-theme="dark"] div[role="combobox"] { /* pole select wyświetlane w formularzu */
  background-color: var(--global_control_background) !important;
  color: var(--global_control_color) !important;
}

body[data-theme="dark"] div[role="listbox"]:focus { /* pole select wyświetlane w formularzu */
  background-color: var(--global_control_focused_background) !important;
  color: var(--global_control_color) !important; /* tu błąd */
}

body[data-theme="dark"] div.semanticSelect div.active.selected.item { /* wybrana opcja po rozwinięciu */
  background-color: #114e5d !important;
}

body[data-theme="dark"] div.semanticSelect div.item { /* opcje nie wybrane po rozwinięciu */
  background-color: var(--global_control_background) !important;
  color: white !important;
}

body[data-theme="dark"] div.semanticSelect div.divider.text { /* kolor czcionki pola formy po otwarciu listy rozwijalnej */
  color: var(--global_control_color) !important; 
}

body[data-theme="dark"] div.semanticSelect div.item:hover { /* opcje najechana po rozwinięciu */
  background-color: var(--global_control_focused_background) !important;
  transition: all 0.1s ease-in-out;
}

body[data-theme="dark"] i.dropdown.icon::before { /* ikonka strzałki rozwijalnej */
  color: white !important;
}

/* ======= ~DARK ======= */

/* ================= */
select:invalid {color: #e1e1e1; }   /* nie wybrane select */
select:required:invalid {color: #e1e1e1; }   /* nie wybrane select */
