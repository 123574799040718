body[data-theme="dark"] #acInputSearchForm, body[data-theme="dark"] #acInputSearchForm::placeholder {
  color: var(--global_control_color) !important;
}

.pac-container:after { /* ukrycie powered by google */
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}

.pac-item, span.pac-item-query { /* nie zależnie od theme, zwiększenie font-a */
  font-size: 24px !important; 
  padding-top: 5px; /* rozsunięcie pozycji */
  padding-bottom: 5px;
}

.pac-item:hover, span.pac-item-query:hover { /* zwiększenie fonta przy hoover */
  font-size: 25px !important;
}

/* style dla pola autocomplete, skopiowane z EP */

  body[data-theme="dark"] .pac-container {  /* #67717f; -26?: #373C44; ewent: #3B4249;*/
    background-color: var(--global_control_background); /* #59626E; */
  }

  body[data-theme="dark"] .pac-item, body[data-theme="dark"] span.pac-item-query { /* tekst menu z nagłówkiem */
    color:#e4e4e4;
  }

  body[data-theme="dark"] .pac-item:hover, body[data-theme="dark"] span.pac-item-query:hover {
    background-color: var(--global_control_focused_background) !important; /*#4E5560 -15 trochę słabo widać, -17: #4B535D,  #67717f */
    color: white;
  }



  /* .dropdown-menu {
    display: block !important;
  } */

  /* style dla pola autocomplete, skopiowane z EP */
