/* dolny komunikat pod formami, np. przy send reset password mail - nie znaleziono */
[data-theme="dark"] .ui.error.bottom.attached.message {
  background-color: #1d1e21 !important;
  color: #e0b4b4 !important;
  font-weight: normal;
  font-size: 18px !important;
}

[data-theme="dark"] .ui.error.bottom.attached.message > i { /* ikona */
  color: red !important;
}

.ui.error.bottom.attached.message { /* zmniejszenie czcionki */
  font-weight: normal;
  font-size: 20px;
}

.error.attached.message {
  font-weight: bold;
}

.error.attached.message {
  font-weight: bold;
}
