/* === rozsunięcie && zwiększenie wysokości w pionie */
div.centered.row.group-caption-background {
  margin-top: 25px;
  margin-bottom: 25px;
}

div.group-caption-text {
  padding: 16px;
}

/* === ~rozsunięcie */

/* === kolor tła i czionki, border dolny */

[data-theme="dark"] div.centered.row.group-caption-background {
  background-color: #2b3947;
  border: 0px; /* było 1px na dole */

} 

[data-theme="dark"] div.group-caption-text {
  color: #c5e1ff;
}
