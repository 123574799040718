/* ! UWAGA: JAK NAJDOKŁADNIEJ OPISYWAĆ DO JAKIEGO ELEMENTU 
          I PO CO DOKŁADNIE STYL, W TEJ SAMEJ LINII */

/* =========== DROBNE STYLE pojedyńcze =========== */

/* =======TESTY======== */
/* ======= pamiętać o !important ======= */

/* #25313d w sumie lepsze, tylko rochę przyciemnione: 303f4f, niech będzie ten: #2b3947, powstał z przyciemnienia górnego*/

/* tekst nagłówka: class="group-caption-text"  88daf3, 8bb8e4 - lekko niebieski, */
/* dosyć dobry: #b0c9e4, niech będzie ten: #c5e1ff */

input[type=text]  /* np. w celu uzyskania tego samego rozmiaru * w polu model, co w select-ach */
{
  font-family: serif !important; /* selecty-y mają serif */
}

/* === ~kolor tła i czionki, border dolny */

/* ======================== */




/* =======~TESTY======== */

/* może wybadać dokładnie do czego one są */


hr.imageHr {  /* graficzna linia rozdzielająca, używana przy opisie */
  background: url(../../public/assets/hr.png) repeat-x 0 0;
  height: 6px;
  width: 100%;
  border: 0px;
}

div.imgNotice { /* ikonka używana przy opisie ogłoszenia, przypominająca plik tekstowy */
  width: 100%;
  height: 100%;
  background: url(../../public/assets/notice.png) no-repeat;
}



/* =========== ~DROBNE STYLE =========== */




