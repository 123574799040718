

/* odległość label-a od pola na mobile, inaczej text dotyka pola, 
niestety style przenikają, dlago nie można użyć tego na div.column bo wtedy nawet formularz search jest rozsuwany */
div.labelStyle1 {  
  padding: 5px;
  font-weight: bold;
}

div.labelStyle1NoBold {  
  padding: 5px;
}

.header {
  text-align: center;
}

div.txtProp { /* np. we ViewAdv - listowanie props */
  padding-right: 35px;
  display: inline-block;
}

div.txtEqu { /* np. we ViewAdv - wyposażenie */
  /* line-height: 35px;  */
  padding-left: 15px;
  font-size: 24px !important;
  line-height: 50px;
}

div.txtDesc { /* np. we ViewAdv - opis */
  font-size: 24px !important;
  line-height: 40px;
}

span.textCaption { /* np. we ViewAdv - nagłówek opisu */
  position: relative;
  top: 2px;
  font-weight: bold;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 5px;
}
