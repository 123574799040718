.fontDesc {
  line-height: 25px; /* 24 */
  font-size: 20px;
}

.highlighted:hover {
  background-color: #DBDBDB;
  cursor: pointer;
}

.backgroundLight {
  background-color: #E5E5E5; /* #E5E5E5; */
}