body[data-theme="dark"] div.ui.mini.pagination.menu { /* pasek nawigacji */
  background-color: #18252b !important;
  /* background-color: #253841 !important; */
}

body[data-theme="dark"] div.ui.mini.pagination.menu a { /* czcionka, tj. elementy a wewnątrz */
  color: white !important;
}

body[data-theme="dark"] div.ui.mini.pagination.menu a:hover { /* najechana czcionka, tj. elementy a wewnątrz */
  background-color: #253841;
  /* background-color: #18252b; */
  color: orange !important;
}