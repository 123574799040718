/* ============ LAYOUT ============ */

/* for smooth transition, themes */
* {
  transition: all 0.3s ease-in-out;
}

html {
  height: 100%;
}

div#root {
  height: 100%; /* remove this line to see div.app is no more 100% height */
  padding: 0px; /* wyzerować auto padding */
  margin: 0px;
}

#page-container {
  position: relative;
  
  min-height: 100%;   /* min-height */
  display: flex;
  /* border: 5px solid orange; */
}

#content-wrap {
  padding-bottom: 5rem;    /* Footer height */
  
  min-height: 100%;
  width: 100%;
  display: flex;
  /* border: 3px solid green; */
}

#footer {  /* footer też jest stylowany po id w odrębnym pliku */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;            /* Footer height dom 5 */
}

/* ============ ~LAYOUT ============ */

/* 
  TAG CONTAINER
div.ui.container {
  padding-top: 87 !important;
  padding-bottom: 0px !important;
} */

div.bodyContentWrapper { /* zerowy: 87, 0, było 30 oryginalnie we ViewAdv, przestrzeń body, wolne odstępy z góry i dołu, minimalne, ten tag jest w App.tsx, aby zobaczyć jego działanie warto włączyć border dokoła tagu grid po return  */
  padding-top: 167px;
  width: 100%; 
  margin: 0 auto;
  padding-bottom: 80px;
} /* prawidłowe style na grid: <Grid textAlign="center" verticalAlign="middle" style={{margin: '0px', height: "100%" }}> */
