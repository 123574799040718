/* w wartości zmiennych css nie wolno używać cudzysłowów */

:root {
  --global_font_color_basic: black;
}

body[data-theme="dark"] {
  --global_font_color_basic: #d3d3d3;  /* '#d3d3d3'; */  
  
  --global_control_color: white; /* czcionka, text-input, select */
  --global_control_background: #32353a; /* #32353a; */
  --global_control_focused_background: #4a4e55;
}
