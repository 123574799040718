/* === PASEK MENU NAWIGACYJNY === */

/* ===== ZMIENNE ===== */

:root {
  --navbar_background: linear-gradient(135deg, rgb(24, 42, 115) 0%, rgb(33, 138, 174) 69%, rgb(32, 167, 172) 89% );
}

[data-theme="dark"] {
  --navbar_background: linear-gradient(135deg, #0d173f, #124e62, #0e4e50);
}

/* ===== ~ZMIENNE ===== */

.ui.inverted.top.fixed.menu {
  background-image: var(--navbar_background) !important;
  background-size: cover;
}

/* === ~PASEK MENU NAWIGACYJNY === */

/* */

/* .masthead, .body {
  display: flex;
  align-items: center;
  height: 100vh;
} */

/* .masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
} */

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.ui.left.icon.input {
  width: 100% !important;
}

.header {
  text-align: center;
}
