/* nie wolno używać znaków ' ' do opisywania styli, bo są ignorowane */

table {
  margin: 10px;  /* rozdział między liniami formularza */
}

.fieldCaption {
  padding-right: 10px;
  width: 80px;
  text-align: right;
  vertical-align: middle;
}

div.row {
  padding: 0px !important; /* przy złożeniu breakpoint są linie, bez important tag nie działa */
}

#id1 {
  border: '2px solid red !important';
}

input.placeholderText::placeholder {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}