


div.lineSpacer { /* szara linia rozdzielająca z marginesem rozsuwającym wiersze */
  border-bottom: 1px solid #e8e9e9;
  margin: 12px 8px;
  
  width: 100%; 
  margin-left: 12px;
  margin-right: 12px;
}
