body[data-theme="dark"] #rc-imageselect {  /* tło okna z puzlami recaptcha */
  background-color: rgb(15, 15, 98) !important;
}

#rc-imageselect {
  background-color: rgb(15, 15, 98) !important;
}

.center_recaptcha > div > div {  /* do centrowania recaptcha */
  display: flex;
  place-content: center;
}