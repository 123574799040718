/* stylowanie jedynie w przypadku dark theme */

/* zmniejszenie pionowego rozsunięcia elementów listy */
div.result 
{
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

div.results 
{
  border: 1px solid #85b7d9 !important;
}

/* === ELEMENT LISTY AUTOCOMPLETE === */

/* pojedyńczy element listy, nie najechany */
body[data-theme="dark"] div.result { 
  background-color: var(--dark_menu_opt_bgc_unhovered) !important;
}

/* pojedyńczy element listy, najechany */
body[data-theme="dark"] div.result:hover { /* :hover musi iść bez spacji */
  background-color: var(--dark_menu_opt_bgc_hovered) !important;
}

/* tekst listy: nagłówek */
body[data-theme="dark"] div.content div.title { 
  color: #e9e9e9 !important;
}

/* tekst listy: opis */
body[data-theme="dark"] div.content div.description { 
  color: #bcbcb6 !important;
}

/* zrezygnować z hoover-a dla podświetlania tekstu, 
   gdyż efekt ma miejsce dopiero po najechaniu na tekst 
   a efekt powinien być też dla elementu przecież   
*/
/* div.content div.title:hover {
  color: orange !important;
}
*/

/* === KOMUNIKAT: BRAK WYNIKU === */

/* domyślnie czerwony 
   też wymaga oddzielnego stylu koloru czcionki */

/* samo tło elementu "brak wyniku" */
body[data-theme="dark"] div.message.empty { 
  background-color: var(--dark_menu_opt_bgc_unhovered) !important;
}

body[data-theme="dark"] div.message.empty div.header { /* nagłówek "brak wyniku" */
  color: #e9e9e9 !important; /* musi być z important */
}

body[data-theme="dark"] div.message.empty div.description { /* tekst pod nagłówkiem */
  color: #d4d4cc !important;
}

/* === ~komunikat: brak wyniku === */
