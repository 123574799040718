/* body, font */

:root {
  --body_background: #eaeaea;
}

/* ===== ZMIENNE DARK ===== */

body[data-theme="dark"] {
  --body_background: #111113;
  /* zmienna global jest już ustawiana w miejscu deklaracji */
}

/* ============ */

.ui.header {  /* wszelakie header-y, np. h1, h2, h3, znacznik body tego nie obejmuje */
  color: var(--global_font_color_basic) !important;
}

body {
  background-color: var(--body_background);
  color: var(--global_font_color_basic); /* zmienna globalna */
  
  /* pozostałe style */
  line-height: 1;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  height: 100%;
}



/* ====== DARK ====== */



