/* te style są konieczne, tzn. bez nich obrazek w preview się rusza */

.preview-img-box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 50px;
  /* border: 2px solid yellow; bez cudzysłowów */
}
.img-preview {
  overflow: hidden;
}

.cropper-caption {
  padding: 6px;
  /* border: 2px solid green; */
}