/* === style nagłówka w formularzu create adv === */
.group-caption-background {
  background-color: #fafafa;
  border-bottom: 1px solid #e8e9e9;
  margin: 10px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.group-caption-text {
  font-weight: bold;
  color: #086eb8;
  padding: 12px;  
}

.caption-top-spacer {
  padding-bottom: 25px !important;  /* bez important nie dziala */
}
/* ~=== */