/* POPRAWKI PÓL SELECT DISNTANCE ORAZ PRZYCISKU SZUKAJ, TJ. BEZ
   TYCH ZMIAN WYŚWIETLAŁY SIĘ W 2 LINIACH */

div.distanceSelect div.divider.text { /* ustawienie rozmiaru czcionki na nieco mniejszą */
 font-size: 20px;
}

div.distanceSelect { /* uniknięcie rozbicia na 2 linie z km drugiej lini */
  min-width: 140px;
}

.fieldSearchButton { /* zmniejszenie rozsunięcia pola select distance i search button celem uzyskania dodatkowego miejsca */
  padding-left: 10px !important; /* musi być z important, by default jest ze 30 */
}

div.searchButton > button { /* umożliwienie wyświetlania tekstu w 1 linii, poprzez dopuszczenie tekstu bliżej brzegów przycisku */
 padding-left: 0px !important;
 padding-right: 7px !important;
 height: 70px !important; /* bez tego stylu przycisk jest niższy niż sąsiednie pole */
}

div.searchButton i { /* umożliwienie wyświetlania tekstu w 1 linii, poprzez dopuszczenie tekstu bliżej brzegów przycisku */
  padding-left: 12px !important;
}
