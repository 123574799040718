/* === PRZYCISKI === */

 body[data-theme="dark"] .ui.basic.button {
  background-color:#50535a !important; /* #5e626a musi być z important */
  color: white !important;
 }

 body[data-theme="dark"] .ui.basic.button:hover {
  background-color:#5e626a !important; /* #757a84 musi być z important */
  color: white !important;
 }

 body[data-theme="dark"] .ui.button {
  background-color: #137029 !important; /* oryg: #21ba45, zmieniony: #188c33 */
 }

 body[data-theme="dark"] .ui.button:hover {
  background-color: #178330 !important;
 }

/* === ~ PRZYCISKI === */

/* === PRZYCISKI POD FORMĄ === */
/* przycisk wyślij ponownie */
body[data-theme="dark"] .ui.message > button { 
  margin-top: 10px;
 }
 
 body[data-theme="light"] .ui.message > button { 
   margin-top: 10px;
 }
  /* === ~PRZYCISKI POD FORMĄ === */

  /* PRZYCISK "ZŁOŚ OGŁOSZENIE */
  
  body[data-theme="dark"] button.ui.yellow.small.basic.button { /* przycisk nie podświetlony */
    background-color: black !important;
  }

  body[data-theme="dark"]  button.ui.yellow.small.basic.button:hover {
    background-color: #5e626a !important;
  }
  