/* nadana klasa to searchResultsRow */

/* LISTA - WYNIKI WYSZKIWANIA */

div.row.searchResultsRow { /* przyspieszenie transition */
  transition: all 0.15s ease-in-out;
}

body[data-theme="dark"] div.row.searchResultsRow { /* wiersz - rezultat */
  background-color: #18191b !important;
  color: white;
} 

body[data-theme="dark"] div.row.searchResultsRow:hover { /* najechany wiersz - rezultat */
  background-color: var(--global_control_background) !important;
} 

/* POLE WYBORU KRYTERIÓW - ma klasę semanticSelect ustawioną w tsx*/

/* div.searchCriteriaRow, div.searchCriteriaRow:hover {
  background-color: black !important;
} */