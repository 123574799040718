/*
  2 div-y w lini, tj. akceptuję + regulamin 
  według: https://dev.to/dawnind/3-ways-to-display-two-divs-side-by-side-3d8b
*/

.parent {
  /* border: 1px solid blue !important; */
  text-align: center;
}
.child {
  display: inline-block;
  /* border: 1px solid red !important; */
  vertical-align: top;
  /* height: 60px; */
  width: 170px;
}
