/* ======= ZMIENNE ======= */

:root {
  --footer_background: #c4c4c4;
  --footer_top_border_color: #acacac;
  --footer_font_color: #3f3f3f;
}

body[data-theme="dark"] {
  --footer_background: #232327;
  --footer_top_border_color: #272e3a;
  --footer_font_color: #a1a1a1;
}

/* ======= ~ZMIENNE ======= */

#footer {
  border-top: 2px solid var(--footer_top_border_color);
  background-color: var(--footer_background);
}

div.footerCssWrapper h4.ui.header { /* nagłówek footer-a ! jest nadpisywane */
  position: relative; top: 26px;
  color: var(--footer_font_color) !important;
}